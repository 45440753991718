const download = (data, headers,filename) => {
  var blob = new Blob([data])
  var downloadElement = document.createElement('a');
  var href = window.URL.createObjectURL(blob); //创建下载的链接
  downloadElement.href = href;
  filename = filename ? filename : new Date().getTime() + '.xlsx'
  downloadElement.download = filename; //下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); //点击下载
  setTimeout(() => {
    document.body.removeChild(downloadElement);//下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
  }, 200)
}
export default { download }
